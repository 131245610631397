import React, { useCallback } from "react";
import "./FileUpload.css";

import { useDropzone } from "react-dropzone";
import latinize from "latinize";

import DeliveryDates from "./DeliveryDates";

import fileService from "../services/files";
import metadataService from "../services/metadata";

import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const FileUpload = ({
  delivery,
  files,
  updateLastModified,
  setFiles,
  notify,
  creatorDesignation,
  user,
  creator,
  toggleLoading,
  setMultipleMetadata,
}) => {
  // File doesn't provide actual full path, so we can't be sure that two of them
  // are pointing to the same physical files unless we actually check contents.
  // This should be good enough unless someone is trying to force a collision.
  const id = (file) => `${file.path}${file.lastModified}${file.size}`;

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const name = (file) => {
        const splitByFinalDot = file.name.split(/\.(?=[^.]+$)/);
        const name = `${creatorDesignation} ${splitByFinalDot[0]}`;
        const extension = splitByFinalDot[1];

        return `${latinize(name)?.replaceAll(
          /[^a-zA-Z0-9-]+/g,
          "_"
        )}.${latinize(extension)?.replaceAll(/[^a-zA-Z0-9-]+/g, "_")}`;
      };
      const byId = (firstFile, secondFile) =>
        firstFile.id.localeCompare(secondFile.id);

      if (fileRejections.length) {
        notify("Några filer var för stora för att laddas upp.", "danger");
      }

      const processedFiles = acceptedFiles
        .filter(
          (file) => !files.some((existingFile) => existingFile.id === id(file))
        )
        .map((file) => ({
          file,
          id: id(file),
          name: name(file),
          checked: false,
        }));

      Promise.all(
        processedFiles.map(async (file) => {
          return {
            ...file,
            metadata: await metadataService.extractMetadata(file.file),
          };
        })
      ).then((metadataFiles) => {
        setFiles(files.concat(metadataFiles).sort(byId));
      });
    },
    [files, setFiles, notify, creatorDesignation]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 50 * 1024 * 1024,
    onDrop,
  });

  const navigate = useNavigate();

  // Hard coded for now, may eventually be chosen by user (and actually sent)
  const date = new Date();
  const diaryYear = date.getUTCFullYear();
  const startDate = date.toISOString().split("T")[0];
  const endDate = new Date(date.setFullYear(diaryYear + 1))
    .toISOString()
    .split("T")[0];

  const handleCheckAll = ({ target }) => {
    setFiles(files.map((file) => ({ ...file, checked: target.checked })));
  };

  const handleCheck = ({ target }) => {
    setFiles(
      files.map((file) =>
        file.id === target.className
          ? { ...file, checked: target.checked }
          : file
      )
    );
  };

  const handleDelete = async () => {
    const filesToBeDeleted = files.filter((file) => file.checked);
    const filesToRemain = files.filter((file) => !file.checked);

    await fileService.removeIfExists(delivery.deliveryId, filesToBeDeleted);

    setFiles(filesToRemain);
    updateLastModified(delivery.deliveryId);
  };

  const handleEdit = ({ target }) => {
    const fileToBeEdited = files.find((file) => file.id === target.id);

    // Uncheck because otherwise it will stay checked in App state
    setFiles(
      files.map((file) =>
        file.id === fileToBeEdited.id ? { ...file, checked: false } : file
      )
    );
    navigate(`/metadata/${fileToBeEdited.id}`);
  };

  const handleEditMultiple = () => navigate(`/metadata/multiple`);

  const handleDelivery = async (event) => {
    event.preventDefault();

    try {
      toggleLoading(true);

      const apiMetadatas = await fileService.submitFiles(
        files,
        delivery,
        user,
        creator.id
      );

      setMultipleMetadata(files, apiMetadatas);
      updateLastModified(delivery.deliveryId);
      notify("Dina ändringar är sparade.", "info");
    } catch (e) {
      notify("Något gick fel.");
    } finally {
      toggleLoading(false);
    }
  };

  const requiredFieldFilled = (file) =>
    file.metadata &&
    file.metadata.title &&
    file.metadata.rights &&
    file.metadata.dates.length;

  const loggedInAsAdmin = user?.roles?.includes("ROLE_ADMIN");

  return (
    <>
      <h2>Filer i inleverans {delivery.deliveryId}</h2>
      <Form onSubmit={handleDelivery}>
        <DeliveryDates
          diaryYear={diaryYear}
          startDate={startDate}
          endDate={endDate}
        />
        <div className="d-flex">
          <div id="file-table" className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      aria-label="Markera alla"
                      onChange={handleCheckAll}
                    />
                  </td>
                  <td>Filnamn</td>
                  <td>Obligatorisk metadata?</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={file.id}>
                    <td>
                      <input
                        type="checkbox"
                        aria-label={`Markera ${file.name}`}
                        className={`${file.id}`}
                        checked={files[index].checked}
                        onChange={handleCheck}
                      />
                    </td>
                    <td>{file.name}</td>
                    <td>{requiredFieldFilled(file) ? "Ja" : "Nej"}</td>
                    <td>
                      <Button type="button" id={file.id} onClick={handleEdit}>
                        Redigera
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {loggedInAsAdmin || (
            <div className="dropzone" id="dropzone" {...getRootProps()}>
              <input data-testid="dropzone-input" {...getInputProps()} />
              <i>
                <p>Bifoga filer genom att dra och släppa filerna här.</p>
                <p>Flera filer kan bifogas samtidigt.</p>
                <p>Maximal storlek per fil är 50 MB.</p>
              </i>
              <div>
                <Button>Bifoga filer</Button>
              </div>
            </div>
          )}
        </div>
        <Row className="my-4 w-100 justify-content-between">
          <div>
            <Button className="mx-2" onClick={() => navigate(`/deliveries`)}>
              Inleveranser
            </Button>
          </div>
          <div>
            <Button className="mx-2" onClick={handleEditMultiple}>
              Redigera flera filer samtidigt
            </Button>
            <Button className="mx-2" type="reset" onClick={handleDelete}>
              Ta bort markerade filer
            </Button>
            <Button className="mx-2" type="submit">
              Spara
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default FileUpload;
